import React, { useEffect, useState } from 'react';
import { SafeAreaView, Platform} from "react-native";
import Axios from 'axios';
import { useEnv } from './AppContext';
import { routerServer } from './Global';
import GSS from './GlobalStyleSheet2.js'
import * as WebBrowser from 'expo-web-browser';
//import { ResponseType, makeRedirectUri, useAuthRequest } from 'expo-auth-session';
import * as Linking from "expo-linking"

const GOOGLE_WEB_CLIENT_ID = "253648496299-d571bcrgi10guakq0mi1e5f45n04f6pr.apps.googleusercontent.com";
//const GOOGLE_APP_CLIENT_ID = "253648496299-26oe374noi3dccqlqsrrj5ooecau1s4s.apps.googleusercontent.com";
//const REDIRECT_URI = "http://iztel.iptime.org:7777/google/google_redirect"
//const REDIRECT_URI = "http://myshott.chyh.kr:7777/google/google_redirect"
const REDIRECT_URI = "https://myshott.kr/google/google_redirect"
WebBrowser.maybeCompleteAuthSession();

const discovery = {
    authorizationEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
    tokenEndpoint: 'https://oauth2.googleapis.com/token',
}
//const redirectUri = makeRedirectUri();  

// export const useGoogleAuth = () => {
//     const [request, response, promptAsync] = Google.useIdTokenAuthRequest(
//         {
//           androidClientId: GOOGLE_CLIENT_ID,
//           //redirectUri: 'http://iztel.iptime.org:7777/google/google_redirect',
//           //redirectUri: 'http://myshott.chyh.kr:7777/google/google_redirect',
//           //redirectUri: "myshott://home",
//           //redirectUri: redirectUri,
//           redirectUri: makeRedirectUri({
//             scheme: 'myshott',
//           }),         
//           //responseType : ResponseType.IdToken,
//         }
//     ); 

//     function decodeIdToken(idToken) {
//         // JWT를 점(.)으로 구분하여 분할합니다.
//         const [headerEncoded, payloadEncoded, signatureEncoded] = idToken.split('.');
      
//         // Base64 URL-decoded 형식의 페이로드를 디코딩합니다.
//         const payloadDecoded = atob(payloadEncoded.replace(/_/g, '/').replace(/-/g, '+'));
      
//         // 디코딩된 페이로드를 JSON 객체로 변환합니다.
//         const payload = JSON.parse(payloadDecoded);
      
//         return payload;
//       }

//     React.useEffect(() => {
//         if (response?.type === 'success') {
//           const { id_token } = response.params;
//           console.log(id_token);
//           //fetchAccessToken3(id_token);
//           // const idTokenPayload = decodeIdToken(id_token); // yourIdToken을 실제 토큰으로 대체하세요.
//           // console.log(idTokenPayload)      
//         }
//     }, [response]);
  
//     return { promptAsync };
// };


export const useGoogleAuth2 = () => {
  const [result, setResult] = useState(null);

  // useEffect(() => {
  //   if (result?.type === "success") {
  //     // const { token, iv } = getParamsFromUrl(result.url);

  //     // const session = decrypt(token, key, iv);
  //     // console.log({ session });
  //     console.log(result.url);
  //   }
  // }, [result]);

  // const googleAuth = async () => {
  //   //const baseUrl = "https://...com";
  //   const callbackUrl = Linking.createURL("App", { scheme: "myapp" });

  //   setResult(
  //     await WebBrowser.openAuthSessionAsync(
  //       // `${baseUrl}/login?returnUrl=${encodeURIComponent(
  //       //   `${baseUrl}/_v/login?token=...&iv=...&returnUrl=${callbackUrl}`
  //       // )}`,
  //       // callbackUrl
  //       `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${GOOGLE_WEB_CLIENT_ID}&redirect_uri=${callbackUrl}&scope=https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile&access_type=offline&state=1234_purpleGoogle&prompt=consent`,
  //       callbackUrl        
  //     )
  //   );
  // }
  const googleAuth = async () => {
    try {
      const prefix = Linking.createURL("/");
      //console.log(ENV.target_after_login);
      //console.log(prefix);
      //const callbackUrl = Linking.createURL("App", { scheme: "myapp" });
      const response = await WebBrowser.openAuthSessionAsync(
        `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${GOOGLE_WEB_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile&access_type=offline&state=${prefix}&prompt=consent`,
        REDIRECT_URI);
      setResult(response);
    } catch (error) {
      console.error('Google Auth Error:', error);
    }
  };

  return { googleAuth };
};

