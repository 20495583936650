import React from 'react';
import Toast from 'react-native-root-toast';
import { Platform, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const routerServer = 'https://myshott.kr';
//export const routerServer = 'http://iztel.iptime.org:7777';
//export const routerServer = 'https://myshott.com';
//export const routerServer = 'http://eva.iptime.org:7777';
//export const routerServer = 'https://my.chyh.kr';
export const appVersion = '1.0.33';
export const targetOS = 'ios';

export const showToast = (message, durationTime = Toast.durations.SHORT) => {
    Toast.show(message, { duration: durationTime });
};

export async function  SaveToSystem(sysData)
{
    ///console.log('Before save : ', sysData.user_id);
    try {
        const stringifiedData = JSON.stringify(sysData);

        if (Platform.OS === 'web') {
            localStorage.setItem('sys_data', stringifiedData);
        } else {
            await AsyncStorage.setItem('sys_data', stringifiedData);
        }
    } catch (e) {
        console.error('Saving error: ', e);
    }
    //console.log('Before save : ', sysData.user_id);
}

export async function LoadFromSystem()
{
    try {
        let sysDataString;

        if (Platform.OS === 'web') {
            sysDataString = localStorage.getItem('sys_data');
        } else {
            sysDataString = await AsyncStorage.getItem('sys_data');
        }
        return JSON.parse(sysDataString);
    } catch(e) {
        console.error('Loading error: ', e);
        return null; 
    }
}

export async function RemoveSystemData()
{
    try {
        if (Platform.OS === 'web') {
            //sysDataString = localStorage.getItem('sys_data');
        } else {
            await AsyncStorage.removeItem('sys_data');
        }
    } catch(e) {
        console.error('Deleting error: ', e);
        return null; 
    }
}

export async function PrintSystemData()
{
    try {
        let sysDataString;
        if (Platform.OS === 'web') {
            sysDataString = localStorage.getItem('sys_data');
        } else {
            sysDataString = await AsyncStorage.getItem('sys_data');
        }
        console.log('System Data : ', JSON.parse(sysDataString));
    } catch(e) {
        console.error('Printing error: ', e);
    }
}

export function SplitNickName(nickname) 
{
    // ','를 기준으로 문자열 분리
    const parts = nickname.split(',', 2); // 최대 2개의 부분으로 분리

    // 첫 번째 부분에서 숫자(아이디) 추출
    const user_id = parts[0];

    // 두 번째 부분 (나머지 문자열), 닉네임 추출
    const nick_name = parts[1] || '';

    return { user_id, nick_name };
}

// 단어 수에 따라 텍스트를 잘라내는 함수
export const truncateByWords = (text, limit) => {
    const wordArray = text.split(' ');
    if (wordArray.length > limit) {
      return wordArray.slice(0, limit).join(' ') + '...';
    } else {
      return text;
    }
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    } else {
      return str;
    }
}

export const HorizontalLine = ({ color = 'gray', thickness = 1, verticalMargin = 10 }) => {
    return (
      <View
        style={{
          borderBottomColor: color,          // props로 받은 색상 사용
          borderBottomWidth: thickness,       // props로 받은 두께 사용
          marginVertical: verticalMargin,     // props로 받은 상하 마진 사용
        }}
      />
    );
};

let targetAfterLogin = "Home";

export const setTargetAfterLogin = (value) => {
    targetAfterLogin = value;
};

export const getTargetAfterLogin = () => targetAfterLogin;

// 웹 코드에서만 사용
export const getOperatingSystem = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
  
    return os; // 'Windows', 'iOS', 'Android', 'Mac OS', 'Linux' 등
};    

// const getOperatingSystem = () => {
//     const userAgent = window.navigator.userAgent;
  
//     if (/Android/.test(userAgent)) {
//       return 'Android';
//     } else if (/iPhone/.test(userAgent)) {
//       return 'iOS';
//     } else if (/Macintosh/.test(userAgent)) {
//       return 'Mac OS';
//     } else if (/Windows/.test(userAgent)) {
//       return 'Windows';
//     } else {
//       return 'Other';
//     }
// };