import React, {useEffect, useState} from 'react';
import { Text, View, ScrollView, Platform } from 'react-native';
import { useAuth, useEnv, OTTs, useSystemInfo} from '../AppContext';
import { useItemContext } from '../ItemContext';
import GSS from '../GlobalStyleSheet2';
import TopHeader from '../TopHeader';
import { ProgressNext } from '../ProgressButton';
import { CheckCertiIDOfDB } from '../api';
import { useFocusEffect } from '@react-navigation/native';
import { targetOS } from '../Global';

function ManagerShare11 ({navigation, route}) {
    const { isLoggedIn, login, logout } = useAuth();
    const {Item, setItem} = useItemContext();
    const { systemInfo }= useSystemInfo();
    const [isCheckingCertiID, setIsCheckingCertiID] = useState(false);

    function NextStep()
    {
        // 본인 인증을 스킵
        // if( isCheckingCertiID ) {
        //     const newData = { ...Item }; // 먼저 Item을 펼칩니다.
        //     newData.payment_price = Item.monthly_price*Item.maximum_usage-systemInfo.trade_manager_charge;
        //     setItem(newData);           
        //     navigation.navigate('ManagerShare12');
        // } else {
        //     const targetAfterRegister = 'ManagerShare12';
        //     navigation.navigate('CertifyID', {targetAfterRegister});
        // }
        const newData = { ...Item }; // 먼저 Item을 펼칩니다.
        newData.payment_price = Item.monthly_price*Item.maximum_usage-systemInfo.trade_manager_charge;
        setItem(newData);           
        navigation.navigate('ManagerShare12');
    }
    const NextButton = () => {
        return (
            <ProgressNext
                title={'다음'}
                onPress={NextStep}
            />
        );
    }

    useFocusEffect (
        React.useCallback(() => {
            (async () => {
                try {
                    const ret = await CheckCertiIDOfDB(Item.user_id);
                    //console.log( 'ret : ', ret);
                    if( ret.success) {
                        //console.log(ret.data);
                        if( ret.data.checked_certification === 1 ) {
                            setIsCheckingCertiID(true);
                        }
                    } 
                } catch (e) {
                    console.error(e);
                }
          })();          
        }, [])
    );      
    
    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const ret = await CheckCertiIDOfDB(Item.user_id);
    //             //console.log( 'ret : ', ret);
    //             if( ret.success) {
    //                 //console.log(ret.data);
    //                 if( ret.data ) {
    //                     setIsCheckingCertiID(true);
    //                 }
    //             } 
    //         } catch (e) {
    //             console.error(e);
    //         }
    //     })();        
    // }, []);       
    
    const EventCharge = () => {
        if (systemInfo.trade_manager_charge < 490) {
            return (
                <View>
                    <Text style={[GSS.mainDefaultTextB, {color: '#FF00FF'}]}>현재 마이샷 수수수료 이벤트로 인해 위 490원이 아닌 {systemInfo.trade_manager_charge}원으로 할인 받습니다.</Text>
                </View>
            );
        }
        return null;
    }    

    const CalcPayDate = () => {
        // 현재 날짜를 가져옵니다.
        let currentDate = new Date();

        // 현재 연, 월, 일을 가져옵니다.
        let currentYear = currentDate.getFullYear();
        let currentMonth = currentDate.getMonth();
        let currentDay = currentDate.getDate();

        // 단순하게 한 달을 추가합니다.
        const nextMonthDate = new Date(currentYear, currentMonth + 1, currentDay);
        const month = nextMonthDate.getMonth() + 1;
        const day = nextMonthDate.getDate();
        //console.log("다음 달의 같은 일자:", nextMonthDate.toDateString());
        return `${month}월 ${day}일`;
    }  

    return (
        <ScrollView style={{flex:1}}>
            <View style={GSS.mainContainer}>
                <TopHeader 
                    titleImage={Platform.OS !== targetOS && OTTs[Item.ott_id].iconImage}
                    navigation={navigation}
                >
                    {Item.display_name} 정산내용확인
                </TopHeader>            
                <View style={[GSS.mainViewBox, {flex:0.75}]}>
                <Text style={{fontWeight: 'bold', fontSize: 20,}}>매월 정산받는 금액과 정산일자를 확인해주세요</Text>
                </View>
                <View style={[GSS.mainViewBox, {flex:1.25}]}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 5
                    }}>
                        <Text style={GSS.mainDefaultText}>파티원 {Item.maximum_usage}명에게 받는 금액</Text>
                        <Text style={GSS.mainDefaultTextB}>{(Item.monthly_price*Item.maximum_usage).toLocaleString()}/월</Text>
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 5
                    }}>
                        <View style ={ {
                            justifyContent: 'center',
                            //alignItems: 'center',
                        }}>
                            <Text style={GSS.mainDefaultText}>마이샷 수수료</Text>
                        </View>
                        <View>
                            <Text style={GSS.mainDefaultText}><Text style={{color: '#808080', textDecorationLine: 'line-through', textDecorationColor: '#808080'}}>-990원</Text> -{systemInfo.trade_manager_charge < 490 ? 490 : systemInfo.trade_manager_charge}원</Text>
                            <Text style={GSS.mainDefaultTextB}><Text style={GSS.textEmphasis}>(*파티장 할인 적용)</Text></Text>
                        </View>
                    </View>
                    <EventCharge/>
                </View>
                <View style={[GSS.mainViewBox]}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 5
                    }}>
                        <Text style={GSS.mainInfoTextB}>매월 정산받는 금액</Text>
                        <Text style={GSS.mainInfoTextB}><Text style={GSS.textEmphasis}>{(Item.monthly_price*Item.maximum_usage-systemInfo.trade_manager_charge).toLocaleString()}/월</Text></Text>
                    </View>               
                </View>
                <View style={[GSS.mainViewBox]}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 5
                    }}>
                        <Text style={GSS.mainInfoTextB}>첫번째 정산일자</Text>
                        <Text style={GSS.mainInfoTextB}><Text style={GSS.textEmphasis}>{CalcPayDate()}</Text></Text>
                    </View>
                    <View style={{margin: 5}}>
                        <Text style={GSS.mainInfoTextB}>매월 {new Date().getDate()}일에 자동정산 됩니다</Text>
                    </View> 
                </View>
                <View style={[GSS.mainViewBox, {margin: 5, flex:0.5, alignItems:'center', justifyContent: 'center'}]}>
                <NextButton/>
                </View>   
            </View>
        </ScrollView>
    );
};

export default ManagerShare11;