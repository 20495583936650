import React, {useEffect, useState } from 'react';
import { Text, View, Image, StyleSheet, SafeAreaView, TouchableOpacity, ActivityIndicator, Platform} from 'react-native';
import GSS from '../GlobalStyleSheet2';
import { useEnv, useSystemInfo } from '../AppContext';
import ProgressButton from '../ProgressButton';
import SimpleIcon from '../../assets/80px-Interactive_icon.png'
import PhoneIcon from '../../assets/booking.png'
import { WebView } from 'react-native-webview';
import { routerServer, SaveToSystem, LoadFromSystem } from '../Global';
import { SaveCertiIDToDB } from '../api';
import { useModal } from '../ModalContext';
import TopHeader from '../CloseHeader';
import { useLoginProc } from '../LoginProc2';
import { useItemContext } from '../ItemContext';
import { useInitProc } from '../InitProc';
import { CommonActions } from '@react-navigation/native';

const OK_SERVER = 'https://myshott.kr';

function CertifyID ({navigation, route}) {
    const { ENV } = useEnv();
    const [isProc, setIsProc] = useState(false);
    const [webUrl, setWebUrl] = useState('');
    const [certiCompleted, setCertiCompleted] = useState(false);
    const [ userName, setUserName] = useState();
    const [ userPhone, setUserPhone] = useState();
    const [ userBirth, setUserBirth] = useState();
    const { showModalMessage, confirmModal } = useModal();
    const [targetAfterRegister, setTargetAfterRegister] =  useState(route.params?.targetAfterRegister);
    const { loginProc } = useLoginProc();
    const { setSystemInfo }= useSystemInfo();
    const {Item, setItem} = useItemContext();
    const { initProc} = useInitProc();

    async function NextStep()
    {
        //console.log(targetAfterRegister);
        if( targetAfterRegister ) {
            navigation.replace(targetAfterRegister);
        } else {
            navigation.goBack();
        }
    }   
   
    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const ret = checkAgeOver19('20041220');
    //             console.log(ret);
    //         } catch (e) {
    //             console.error(e);
    //         }
    //     })();        
    // }, []);     

    const NextButton = () => {
        return (
          <ProgressButton
            title={'인증 완료'}
            onPress={NextStep}
            disabled = {certiCompleted ? false : true}
          />
        );
    }  

    function checkAgeOver19(birthDateString) {
        // 생년월일을 Date 객체로 변환
        //const birthDate = new Date(birthDateString);
        const birthYear = parseInt(birthDateString.slice(0,4), 10);
        const birthMonth = parseInt(birthDateString.slice(4,6), 10);
        const birthDay = parseInt(birthDateString.slice(6,8), 10);
        //console.log(birthYear, birthMonth, birthDay);
      
        // 현재 날짜 가져오기
        const currentDate = new Date();
      
        // 만 나이 계산
        let age = currentDate.getFullYear() - birthYear;
        const m = currentDate.getMonth() + 1 - birthMonth;
      
        // 현재 월이 생월보다 작거나, 같은 월이지만 현재 일이 생일보다 작은 경우, 나이에서 1을 뺌
        if (m < 0 || (m === 0 && currentDate.getDate() < birthDay)) {
          age--;
        }
      
        // 만 19세 이상 여부 반환
        return age >= 19;
    }    

    async function SaveCertiID(ok_data, user_id=null) {
        try {
            if ( !checkAgeOver19(ok_data.age) ) {
                const _message = {
                    body : '마이샷은 만19세 이상 서비스 가능 합니다.'
                }
                showModalMessage(_message);
                return false;
            }
            if( user_id === null) {
                user_id = ENV.user_id;
            }
    
            let inviter_code = null;
            if( Platform.OS === 'web') {
                inviter_code = localStorage.getItem('inviter_code');
                //console.log(inviter_code);
            }
            //console.log(ok_data);
            const ret = await SaveCertiIDToDB(ok_data, user_id, inviter_code);
            if( ret.success ) {
                setUserName(ok_data.user_name);
                setUserPhone(ok_data.phone_number);
                setUserBirth(ok_data.age);
                setCertiCompleted(true);
                return true;
            } else {
                const _message = {
                    body : '인증결과를 저장하는데 오류가 발생했습니다. 관리자에게 문의 해 주세요'
                }
                showModalMessage(_message);
            }
            return false;
        } catch (e) {
            console.log(e);
        }
    }
    
    if( Platform.OS !== 'web') {
        onMessage = (event) => {
            const uri = event.nativeEvent.url;
            //console.log(uri);
            if(uri.startsWith(OK_SERVER))
            {
                setIsProc(false);
                // 서버로부터의 응답 데이터 처리
                const ok_data = JSON.parse(event.nativeEvent.data);
                //console.log(ok_data);
                if( ok_data.ok_type ) 
                {
                    if( SaveCertiID(ok_data) ) {
                        NextStep();
                    }
                } else {
                    // 인증 오류
                    const _message = {
                        body : '본인인증에 오류가 발생했습니다. 다시 시도해 주세요'
                    }
                    showModalMessage(_message);
                }
            }        
        }   
    }
    
    function check_eziok() {
        setIsProc(true);
        let target;
        if(routerServer === 'http://my.chyh.kr') {
            target = 'eziok2';
        } else {
            target = 'eziok';
        }
        if( Platform.OS === 'web') {
            const app_type = 'web';
            const url = `${OK_SERVER}/${target}/call_std/${app_type}`;
            window.location.href = url;       
        } else {
            const app_type = 'app';
            const url = `${OK_SERVER}/${target}/call_std/${app_type}`;
            //targetUrl = url;
            //console.log(url);
            setWebUrl(url);
        }
    }

    function check_mok() {
        setIsProc(true);

        let target;
        if(routerServer === 'http://my.chyh.kr') {
            target = 'mok2';
        } else {
            target = 'mok';
        }
        if( Platform.OS === 'web') {
            const app_type = 'web';
            const url = `${OK_SERVER}/${target}/call_std/${app_type}`;
            window.location.href = url;       
        } else {
            const app_type = 'app';
            const url = `${OK_SERVER}/${target}/call_std/${app_type}`;
            setWebUrl(url);
        }
    }

    const CloseButton = () => {
        navigation.goBack();
    };  

    const _confirmCertify = () => {
        check_mok();
    }

    const _confirmCancel = () => {
        if( Platform.OS === 'web') {
            // navigation.reset({
            //     index: 0, // 스택의 첫 번째 항목을 선택
            //     routes: [
            //       {
            //         name: 'Tabs', // 최상위 탭 네비게이션 이름
            //         state: {
            //           routes: [
            //             {
            //               name: 'Home', // Tabs 하위에서 Home 탭으로 이동
            //             },
            //           ],
            //         },
            //       },
            //     ],
            // });
            navigation.replace('Tabs', {
                screen: 'home', // 최상위 Tabs 아래 Share 탭으로 이동
                params: {
                    screen: 'Home' // Share 탭 내부의 Share1 스크린을 지정
                }
              }); 
        } else {
            navigation.reset({
                index: 0,
                routes: [{ name: 'home' }],
            }); 
        }
    }

    function confirmCertify() {
        const _confirm = {
            body : `이후의 마이샷 서비스 이용을 위해 본인인증 과정이 필요합니다`,
            afterOK : _confirmCertify,
            afterCancel : _confirmCancel
        }
        confirmModal(_confirm);
    }

    ///////////////////////////////
    let ok_info = route.params?.ok_info ?? null;
    const saveData = route.params?.saveData;

    if(Platform.OS === 'web') {
        useEffect(() => {
            // async 함수를 정의하고 즉시 호출합니다.
            (async () => {
                try {
                    let user_id;
                    let target_after_login;
                    if(ok_info) {
                        const newENV = await initProc();
                        await loginProc(newENV);
                        user_id = newENV.user_id;
                        target_after_login = newENV.target_after_login;         
                    } else {
                        user_id = ENV.user_id;
                        target_after_login = ENV.target_after_login;
                    }
                    if(targetAfterRegister) {
                        target_after_login = targetAfterRegister;
                    }
                    
                    //console.log(ok_info);
                    if(ok_info) {
                        const tmpTarget = localStorage.getItem('tmp_target');
                        //const loadData = JSON.parse(tmpDataString);
                        if( tmpTarget ) {
                            //console.log(tmpTarget);
                            target_after_login = tmpTarget;
                        }
                        //setTargetAfterRegister(tmpDataString);
                        const tmpItem = localStorage.getItem('tmp_item');
                        if( tmpItem) {
                            const loadItem = JSON.parse(tmpItem);
                            //console.log(loadItem);
                            setItem(loadItem);
                        }
    
                        // URL 디코딩
                        const decodedJson = decodeURIComponent(ok_info);
                        const ok_data = JSON.parse(decodedJson);
                        if( ok_data?.ok_type ) 
                        {
                            SaveCertiID(ok_data, user_id);
                        } else {
                            // 인증 오류
                            const _message = {
                                body : '본인인증에 오류가 발생했습니다. 다시 시도해 주세요'
                            }
                            showModalMessage(_message);
                        }   
                        //console.log(target_after_login);
                        const serverUrl = `${window.location.protocol}//${window.location.host}`;
                        //console.log(serverUrl);
                        let target_url = serverUrl;
                        if( target_after_login) {
                            //navigation.replace(target_after_login);
                            if( target_after_login === 'MyInfoHome') {
                                navigation.replace('Tabs', {
                                    screen: 'MyInfo', // 최상위 Tabs 아래 Share 탭으로 이동
                                    params: {
                                        screen: 'MyInfoHome' // Share 탭 내부의 Share1 스크린을 지정
                                    }
                                });
                                //target_url += '/myinfo';
                            } else if(target_after_login === 'Share1') {
                                navigation.replace('Tabs', {
                                    screen: 'Share', // 최상위 Tabs 아래 Share 탭으로 이동
                                    params: {
                                        screen: 'Share1' // Share 탭 내부의 Share1 스크린을 지정
                                    }
                                });
                            } else if(target_after_login === 'ManagerShare12') {
                                navigation.replace('Tabs', {
                                    screen: 'Share', // 최상위 Tabs 아래 Share 탭으로 이동
                                    params: {
                                        screen: 'ManagerShare12' // Share 탭 내부의 Share1 스크린을 지정
                                    }
                                });                              
                            } else if(target_after_login === 'ChangeBankAccount') {
                                navigation.replace('Tabs', {
                                    screen: 'MyInfo', // 최상위 Tabs 아래 Share 탭으로 이동
                                    params: {
                                        screen: 'ChangeBankAccount' // Share 탭 내부의 Share1 스크린을 지정
                                    }
                                });                              
                            } else if(target_after_login === 'ManagerShare3') {
                                navigation.replace('Tabs', {
                                    screen: 'Share', // 최상위 Tabs 아래 Share 탭으로 이동
                                    params: {
                                        screen: 'ManagerShare3' // Share 탭 내부의 Share1 스크린을 지정
                                    }
                                });                              
                            } else if(target_after_login === 'ManagerShare12') {
                                navigation.replace('Tabs', {
                                    screen: 'Share', // 최상위 Tabs 아래 Share 탭으로 이동
                                    params: {
                                        screen: 'ManagerShare12' // Share 탭 내부의 Share1 스크린을 지정
                                    }
                                });                              
                            }
                        } else {
                            //navigation.replace('Home');
                            navigation.replace('Tabs', {
                                screen: 'home', // 최상위 Tabs 아래 Share 탭으로 이동
                                params: {
                                    screen: 'Home' // Share 탭 내부의 Share1 스크린을 지정
                                }
                            });
                        }
                    } else {
                        if(user_id) {
                            const newENV = { ... ENV};
                            //console.log('targetAfterRegister : ', targetAfterRegister);
                            newENV.target_after_login = targetAfterRegister;
                            await SaveToSystem(newENV);
                            //localStorage.removeItem('tmp_target');
                            //localStorage.removeItem('tmp_item')
                            if( targetAfterRegister ) {
                                localStorage.setItem('tmp_target', targetAfterRegister);
                            }
                            if( Item) {
                                //console.log(Item);
                                const itemDataString = JSON.stringify(Item);
                                localStorage.setItem('tmp_item', itemDataString);
                            }
    
                            // const url = `${OK_SERVER}/eziok/call_std/1`;
                            // window.location.href = url;  

                            //check_mok(); 
                            if( targetAfterRegister === 'MyInfoHome') {
                                check_mok();
                            } else {
                                confirmCertify();                                         
                            }
                        } else {
                            const _message = {
                            body : '로그인을 다시 시도하고 진행해주세요'
                            }
                            showModalMessage(_message);   
                            navigation.reset({
                                index: 0,
                                routes: [
                                    { 
                                        name: 'MyInfoHome',
                                    },
                                ],
                            });              
                        }                       
                    }
                } catch (e) {
                    // 오류를 적절하게 핸들링합니다. (예: 로깅, 사용자에게 메시지 표시 등)
                    console.error(e);
                }
            })();
        }, [ok_info])      
        //////////////////////////////

    } else {
        useEffect(() => {
            if( targetAfterRegister === 'MyInfoHome') {
                check_mok();
            } else {
                confirmCertify();                                         
            }
        }, []);                
    }



    if( isProc === false) {
        return (
            <View style={GSS.mainContainer}>
                <View style={{alignItems: 'center', justifyContent:'center'}}>
                    <Text style={[GSS.mainDefaultText, {marginBottom : 10}]}>본인인증 처리 중입니다. 잠시만 가디려 주세요...</Text>
                    <View style={GSS.activityIndicatorContainer}>
                        <ActivityIndicator size='large' color="#0000ff" />
                    </View>                    
                </View>       
        </View>   
        );
    } else {
        if( Platform.OS === 'web') {
            return (
                <View style={GSS.mainContainer}>
                    <View style={{alignItems: 'center', justifyContent:'center'}}>
                        <Text style={GSS.mainDefaultText}>...</Text>
                        <View style={GSS.activityIndicatorContainer}>
                            <ActivityIndicator size='large' color="#0000ff" />
                        </View>                    
                    </View>       
              </View>
            ); 
        } else {
            return (
                <SafeAreaView style={GSS.droidSafeArea}>
                    <WebView 
                        originWhitelist={['*']}
                        scalesPageToFit={false}
                        //source={{ uri: `${routerServer}/apple/call_apple`, webSecurityEnabled: false }}
                        source = {{ uri: webUrl }}
                        javaScriptEnabled={true}
                        onMessage={onMessage}
                    />
                </SafeAreaView>
            );
        }
    }
};

const SS = StyleSheet.create({
    selectMain: {
        flexDirection:'row', 
        width:150, 
        height:50, 
        borderWidth: 1,
        borderColor: '#dddddd',
        justifyContent:'center', 
        alignItems:'center',
        backgroundColor: '#f5f5f5',
        borderRadius:25
    },
    textMain: {
        height:35,
        borderWidth: 1,
        borderColor: '#dddddd',
        justifyContent:'center', 
        backgroundColor: '#f5f5f5',
        borderRadius:10,
        marginTop:3
    }
  });

export default CertifyID;