import React, {useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, BackHandler  , TouchableOpacity, FlatList, Image, Animated, ScrollView, Platform } from 'react-native';
import { OTTs, useEnv } from '../AppContext';
import { useItemContext } from '../ItemContext';
import GSS from '../GlobalStyleSheet2';
import CurvedBorder from '../CurvedBorder';
import { ProgressNext } from '../ProgressButton';
import { CheckUserItemFromDB } from '../api';
import { useFocusEffect } from '@react-navigation/native';
import { targetOS } from '../Global';
import { Helmet } from 'react-helmet-async';

function ShareHome ({navigation, route}) {

    const [selectedItem, setSelectedItem] = useState(null);
    const {Item, InitItem, setItem} = useItemContext();
    const { getItemById, ENV } = useEnv();
    const [ checkItems, setCheckItems ] = useState([]);
    const [ usingItems, setUsingItems ] = useState();

    // 화면 포커스에 따라 함수를 등록 및 해제
    if(Platform.OS !== 'web') {
        useFocusEffect(
            React.useCallback(() => {
                const onBackPress = () => {
                    navigation.reset({
                        index: 0,
                        routes: [{ name: 'home' }],
                    });            
                    return true;
                };
                BackHandler.addEventListener('hardwareBackPress', onBackPress);
                return () => {
                    BackHandler.removeEventListener('hardwareBackPress', onBackPress);
                };
            }, [ENV.user_id])   // 이 의존성 배열을 지정하지 않으면  ENV.user_id가 변경 되더라도 반영되지 않는다. 포커싱 로직은 실행 되더라도...
        );       
    }

    useFocusEffect (
        React.useCallback(() => {+
            (async () => {
                try {
                    const result = await CheckUserItemFromDB(ENV.user_id); // await를 사용하여 비동기적으로 데이터를 저장합니다.
                    if(result.success) {
                        //return result.data;
                        setCheckItems(result.data.item_status);
                        //console.log(result.data.user_item_status);
                        if( result.data.user_item_status ) {
                            const usings = result.data.user_item_status.split(',');
                            setUsingItems(usings);
                        }
                    } else {
                        console.log('ShareHome.checkData :', result.error);
                    }
                } catch (error) {
                    // 여기에서 에러 로깅을 할 수 있습니다.
                    console.warn('ShareHome.checkData  에러:', error);
                }
          })();          
        }, [])
    ); 

    const useNativeDriver = Platform.OS !== 'web';
    // 애니메이션 값 설정
    const fadeAnim = useRef(new Animated.Value(0)).current;  // 초기 값은 투명 (0)
    const animateTransition = () => {
        // 애니메이션을 초기 상태로 되돌립니다 (fade out 효과를 위해).
        fadeAnim.setValue(0);

        // 다시 애니메이션을 시작합니다 (fade in).
        Animated.timing(
            fadeAnim,
            {
                toValue: 1,
                duration: 500, // 이 값은 애니메이션의 길이나 "느낌"에 따라 조정할 수 있습니다.
                useNativeDriver,
            }
        ).start();
    };    

    useEffect(() => {
        if (selectedItem != null) { // 아이템이 선택된 경우에만 애니메이션 실행
            animateTransition();
        }
    }, [selectedItem]);  // selectedItem이 변경될 때 마다 실행


    const toggleItem = (item) => {
        if (selectedItem === item) {
          //setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
          setSelectedItem(null);
          return;
        } else {
          //setSelectedItems([...selectedItems, item]);
          setSelectedItem(item);
        }
    };

    async function NextStep()
    {
        try {
            Item.item_name = selectedItem.item_name;
            Item.display_name = selectedItem.name;
            Item.ott_id = selectedItem.id;
            //console.log(OTTs[selectedItem.id].item_id);
            if( OTTs[selectedItem.id].item_id === null) {
                const data = await InitItem(Item.item_name)
                OTTs[selectedItem.id].item_id = data.item_id;
                OTTs[selectedItem.id].monthly_price = data.monthly_price;
                OTTs[selectedItem.id].monthly_price2 = data.monthly_price2;
            } else {
                const newData = { ...Item };
                newData.item_id = OTTs[selectedItem.id].item_id;
                newData.monthly_price = OTTs[selectedItem.id].monthly_price;
                newData.monthly_price2 = OTTs[selectedItem.id].monthly_price2;
                setItem(newData);
            }
            //console.log(Item);
            navigation.navigate('JoinShare');
        } catch (error) {
            console.error("Share Home Next Step Error : ", error);
        }
    }

    function SelectedOTT({ selectedItem })
    {
        if (selectedItem != null) {
            const per = 100 - Math.floor((selectedItem.monthly_price / selectedItem.site_price) * 100);
            return(
                <View style={[GSS.mainViewBox, {marginVertical: 10 },]}>
                    <Animated.View // 'View' 대신 'Animated.View' 사용
                        style={[
                            
                            { opacity: fadeAnim }  // 불투명도는 애니메이션 값에 바인딩
                        ]}
                    >
                        <View style={styles2.container}>
                            <View>
                                <View style={[styles2.textBox, { flexDirection: 'row' }]}>
                                    { Platform.OS !== targetOS && (
                                        <Image source={selectedItem.iconImage} style={{ width: 18, height: 18, marginRight: 5 }}/>
                                    )}
                                    <Text style={GSS.mainDefaultText}>{selectedItem.name}</Text>
                                </View>
                                <View style={[GSS.labelContainer, {margin:5}]}>
                                    <Text style={GSS.mianDefaultTextW}>{selectedItem.productName}</Text>
                                </View>
                            </View>
                            <View>
                                <View style={styles2.textBox}>
                                    <Text style={{color: '#808080', textDecorationLine: 'line-through', textDecorationColor: '#808080'}}>
                                        {selectedItem.price}
                                    </Text>
                                </View>
                                <View style={[styles2.textBox, {margin:5}]}>
                                    <Text style={GSS.mainDefaultTextB}>
                                        <Text style={GSS.mainSecondText}>{per}%↓</Text><Text style={GSS.mainInfoTextB}>{selectedItem.monthly_price.toLocaleString()}원</Text>
                                    </Text>
                                </View>                            
                            </View>
                        </View>

                    </Animated.View>
                    <View style={{alignItems:'center', marginTop:5}}>
                        <ProgressNext
                            title='다음'
                            onPress={NextStep}
                        />
                    </View>   
                </View>
             

            );
        } else {
            return(null);
        }
    }

    const renderItem = ({ item }) => {
        const matchedData = checkItems.find(d => getItemById(d.item_id)?.item_name === item.item_name);
        const remainingCount = matchedData ? matchedData.count : 0;
        //const useCheck = usingItems?.include(matchedData.item_id.toString());
        const useCheck = usingItems?.includes(item.item_name);
        return (
            <TouchableOpacity
            onPress={() => {!useCheck && toggleItem(item) }}
            disabled = {!useCheck ? false : true}
            style={[
                styles.mainItem,
                selectedItem === item && GSS.mainSelectedItem,
            ]}
        >
            { Platform.OS === targetOS ? (
                <View style={{width: '100%', height: '100%'}}>
                    <View style={{flex: 1, alignItems:'center', justifyContent:'center', marginBottom: -15,}} >
                        <Text style={GSS.mainInfoTextB}>{item.name}</Text>
                    </View>
                    <CurvedBorder curveValue={-25} borderHeight={26} fillColor={useCheck ? '#8F8C83' : '#291B44'} style={{}}/>
                    <View style={{flex:1.6, alignItems: 'center', justifyContent: 'center', borderBottomLeftRadius:10, borderBottomRightRadius:10,
                        backgroundColor: useCheck ? '#8F8C83' : '#291B44',}}>
                        <Text style={[GSS.mainPriceTextW, {}]}>{item.monthly_price.toLocaleString()}원</Text>
                        <Text style={{color: '#CFCCC3', fontSize:12}}>{remainingCount === 0 ? '자동매칭' : `매칭대기중:${remainingCount}`}</Text>
                    </View>
                </View>
            ) : (
                <View style={{width: '100%', height: '100%'}}>
                    <View style={{flex: 1, alignItems:'center', justifyContent:'center', marginBottom: -15,}} >
                        <Image source={item.logoImage} 
                            resizeMode = 'contain' // Adjust the resizeMode as needed
                            style={styles.mainItemImage} />
                    </View>
                    <CurvedBorder curveValue={-25} borderHeight={26} fillColor={useCheck ? '#8F8C83' : '#291B44'} style={{}}/>
                    <View style={{flex:1.9, alignItems: 'center', justifyContent: 'center', borderBottomLeftRadius:10, borderBottomRightRadius:10,
                        backgroundColor: useCheck ? '#8F8C83' : '#291B44',}}>
                        <Text style={{color: '#CFCCC3'}}>{item.name}</Text>
                        <Text style={[GSS.mainPriceTextW, {marginTop:5}]}>{item.monthly_price.toLocaleString()}원</Text>
                        <Text style={{color: '#CFCCC3', fontSize:12}}>{remainingCount === 0 ? '자동매칭' : `매칭대기중:${remainingCount}`}</Text>
                    </View>
                </View>
            )}
        </TouchableOpacity>
        );
    };    

    const MainContent = () => {
        return (
            <ScrollView style={{flex:1}}>
                <View style={GSS.mainContainer}>
                    <View style={[GSS.mainViewBox]}>
                        <Text style={[GSS.mainSubjectText, {textAlign: 'center'}]}>공유 하고자 하는 OTT를 선택해주세요</Text>
                    </View>
                    <View style={[GSS.mainViewBox, { flex: 1, marginVertical: 5 }]}>
                        <FlatList
                            data={OTTs}
                            numColumns={3} // 한 줄에 3개의 아이템을 표시
                            ItemSeparatorComponent={null}
                            scrollEnabled={false} // 스크롤 비활성화
                            keyExtractor={(item) => item.id.toString()}
                            renderItem={renderItem}
                        />
                    </View>
                    <SelectedOTT selectedItem={selectedItem} />
                </View>
            </ScrollView>
        );
    }    

    if( Platform.OS === 'web') {
        return (
            <React.Fragment>
                <Helmet>
                    <title>마이샷 - 넷플릭스,티빙,웨이브,디지니+ OTT계정공유</title>
                    <meta name="description" content="파티생성, 파티원 가입등을 통해 공유계정 이용, 파티장은 수수료 50% 할인적용, 자동 매칭" />
                    <meta property="og:description" content="파티생성, 파티원 가입등을 통해 공유계정 이용, 파티장은 수수료 50% 할인적용, 자동 매칭" />
                    <meta property="og:image" content="https://myshott.co.kr/og_image.png" />
                    <meta property="og:title" content="파티생성, 파티원 가입, 공유계정 무료이용" />
                </Helmet>                
                <MainContent/>
            </React.Fragment>
        );
    } else {
        return (
            <MainContent/>
        );
    }
};

const styles = StyleSheet.create({
    mainItem: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',    
        borderRadius: 8,
        margin: 5,
        borderWidth: 1,
        borderColor: '#F1F1F1',
        height: 150,
    },
    mainItemImage: {
        width: 30, // Set the width as needed
        height: 30, // Set the height as needed
    },
});


const styles2 = StyleSheet.create({
    container: {
      flexDirection: 'row', // 수평 방향으로 배치
      justifyContent: 'space-between', // 좌측과 우측으로 정렬
      alignItems: 'center', // 수직 가운데 정렬 (원하는 경우 변경 가능)
      paddingHorizontal: 10, // 좌우 여백 설정
      //height: 100, // 필요한 높이로 설정
      padding: 10,
    },
    leftBox: {
      width: 50, // 좌측 상자의 너비 설정
      height: 50, // 좌측 상자의 높이 설정
      backgroundColor: 'blue', // 배경색 설정
    },
    rightBox: {
      width: 50, // 우측 상자의 너비 설정
      height: 50, // 우측 상자의 높이 설정
      backgroundColor: 'red', // 배경색 설정
    },
    textBox : {
        justifyContent: 'center',
        alignItems: 'center',
        margin: 2,
    },
  });

export default ShareHome;